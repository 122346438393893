import React from 'react';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import Row from '../layout/Row';
import FeatherIcon from '../icons/FeatherIcon';
import Typography from '../typography/Typography';
import { TypographyVariant } from 'src/customizations/themes/10-parts/typography';
const FormHeader = ({ title, onClose, onSubmit, style, ...rowProps }) => {
    const [{ styles, theme }] = useTheme(stylesGenerator);
    return (React.createElement(Row, { xAxis: "space-between", ph: theme.spacing.page, pv: theme.spacing.standard.md, style: [styles.header, style], ...rowProps },
        React.createElement(Row, { yAxis: "center", spacing: theme.spacing.standard.sm },
            React.createElement(FeatherIcon, { name: "x", onPress: onClose }),
            React.createElement(Typography, { variant: TypographyVariant.PAGE_TITLE }, title)),
        React.createElement(FeatherIcon, { name: "check", onPress: onSubmit })));
};
const OverlayLayout = {
    FormHeader,
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    header: {
        borderBottomWidth: 1,
        borderBottomColor: theme.palette.border.main,
    },
}));
export default OverlayLayout;
