import React from 'react';
import RowCenter from 'src/components/10-atoms/layout/RowCenter';
import Typography from 'src/components/10-atoms/typography/Typography';
import { TypographyFontWeight, TypographyVariant, } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import withPress from 'src/hoc/withPress';
const Tag = ({ children, removable, textColor, style, ...rowCenterProps }) => {
    const [{ styles, theme }] = useTheme(stylesGenerator);
    return (React.createElement(RowCenter, { spacing: 8, style: [styles.container, style], ...rowCenterProps },
        React.createElement(Typography, { color: textColor, fontWeight: TypographyFontWeight.MEDIUM, variant: TypographyVariant.CAPTION }, children),
        removable && (React.createElement(Typography, { color: theme.palette.icon.primary, variant: TypographyVariant.PAGE_TITLE, style: { lineHeight: 16 } }, "x"))));
};
export const stylesGenerator = createThemedStyle(({ theme }) => ({
    container: {
        paddingHorizontal: theme.spacing.standard.sm,
        paddingVertical: 2,
        backgroundColor: theme.palette.primary.lightest,
        borderRadius: theme.borderRadius.standard.sm,
    },
}));
export default withPress(Tag);
