import React from 'react';
import GenericServerError from 'src/components/10-atoms/GenericServerError';
import QueryHandler from 'src/components/10-atoms/QueryHandler';
import LoaderBase from 'src/components/10-atoms/loaders/LoaderBase';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import QuestionItem from 'src/modules/exam-preparation/containers/QuestionList/QuestionItem';
const QuestionQueryHandler = ({ question, queryState, onRetry, }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    const questionDetails = queryState.data?.ep_questionDetails || question;
    return (React.createElement(QueryHandler, { queryState: {
            ...queryState,
            data: questionDetails
                ? { ep_questionDetails: questionDetails }
                : undefined,
        }, LoaderComponent: React.createElement(LoaderBase, { style: { height: 90 }, mh: theme.spacing.page, mv: theme.spacing.standard.sm }), ErrorComponent: React.createElement(GenericServerError, { error: queryState.error, onRetry: onRetry }), DataComponent: () => (React.createElement(QuestionItem, { variant: "question-details", data: questionDetails })) }));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default QuestionQueryHandler;
