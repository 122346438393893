import { getQuestionLabel } from '../../containers/QuestionList/utils';
export default function getBreadCrumbs(ancestorsDetails, knownNodes, options = {}) {
    const communityTitle = knownNodes?.community?.data.title ??
        ancestorsDetails?.community?.data.title;
    const examLevelTitle = knownNodes?.examLevel?.data.title ??
        ancestorsDetails?.examLevel?.data.title;
    const subject = knownNodes?.subject ??
        ancestorsDetails?.writtenSubject ??
        ancestorsDetails?.oralsSubject;
    const subjectTitle = subject?.data.title;
    const topic = knownNodes?.topic;
    const qpTitle = (knownNodes?.qp ??
        ancestorsDetails?.writtenQP ??
        ancestorsDetails?.oralsQP)?.data?.label;
    const question = knownNodes?.question;
    const path = [
        communityTitle,
        examLevelTitle,
        subjectTitle,
        topic,
        qpTitle,
        question && getQuestionLabel(question),
    ].filter(part => !!part && part !== '__DEFAULT__');
    return options.visibleCount ? path.slice(-options.visibleCount) : path;
}
