import { useApolloClient } from '@apollo/client';
import { last } from 'lodash';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import processHTML from 'src/lib/playlist/players/tts/processors/html';
import processText, { removeMarks, } from 'src/lib/playlist/players/tts/processors/text';
import addSSMLBaseWrapper from 'src/lib/playlist/players/tts/utils/addSSMLBaseWrapper';
import { EP_QUESTION_DETAILS_QUERY, } from 'src/modules/exam-preparation/graphql/question/getQuestionDetails';
import { PlayerType, } from '../../types';
import getBreadCrumbs from 'src/modules/exam-preparation/components/EPBreadCrumbs/getBreadCrumbs';
import { getQuestionLabel } from 'src/modules/exam-preparation/containers/QuestionList/utils';
export default function useEPQuestionDetailsProcessor() {
    const client = useApolloClient();
    const processor = useCallbackWithCtx(async (ctx, item) => {
        const { data, error } = await ctx.client.query({
            query: EP_QUESTION_DETAILS_QUERY,
            variables: {
                questionID: item.meta.questionID,
            },
            fetchPolicy: 'cache-first',
        });
        if (error) {
            throw error;
        }
        const question = data.ep_questionDetails.data.question;
        const ancestorsDetails = data.ep_questionDetails.ancestorsDetails;
        const answer = last(data.ep_questionDetails.answer?.data.answerHistory)?.answer;
        const [questionText, answerText] = await Promise.all([
            removeMarks(processText(question)),
            answer
                ? processHTML(answer)
                : 'Answer missing. Please add an answer if you know it',
        ]);
        const ttsData = addSSMLBaseWrapper(`
          <break time="0.5s"/>
          <emphasis>Question</emphasis>: ${questionText}
          <break time="0.5s"/>
          ${answer ? `<emphasis>Answer</emphasis>: ${answerText}` : answerText}
        `);
        return {
            type: PlayerType.TTS,
            data: ttsData,
            pageDetails: {
                routeName: 'EP_Question',
                routeParams: item.meta,
            },
            title: [
                ...getBreadCrumbs(ancestorsDetails),
                getQuestionLabel(data.ep_questionDetails),
            ].join(' / '),
        };
    }, { client });
    return processor;
}
