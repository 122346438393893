import React, { forwardRef, useImperativeHandle, useState } from 'react';
import Column from 'src/components/10-atoms/layout/Column';
import QuillEditor from '../QuillEditor';
import isEmpty from '../QuillEditor/utils/isEmpty';
import Toolbar from '../Toolbar';
const HTMLEditor = forwardRef(({ initialValue, placeholder, ...columnProps }, ref) => {
    const [quillRef, setQuillRef] = useState();
    useImperativeHandle(ref, () => ({
        getContents: () => quillRef?.getContents(),
        getHTML: () => quillRef?.getSemanticHTML(),
        hasContent: () => (quillRef ? !isEmpty(quillRef) : false),
    }));
    return (React.createElement(Column, { ...columnProps },
        React.createElement(QuillEditor, { onEditorChanged: setQuillRef, readOnly: false, initialValue: initialValue, placeholder: placeholder, flex: true }),
        React.createElement(Toolbar, { quill: quillRef })));
});
export default HTMLEditor;
