import { renderMathInDocument } from 'mathlive';
import React, { useImperativeHandle, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { ScrollView } from 'react-native';
import Column from 'src/components/10-atoms/layout/Column';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import useEffectWithCtx from 'src/hooks/useEffectUtils/useEffectWithCtx';
import { addMathFieldWrapper, normalizeMathField } from '../utils/mathField';
import useStyles from './useStyles';
import createQuillInstance from './utils/createQuillInstance';
const QuillEditor = React.forwardRef(({ initialValue, placeholder, readOnly = false, onEditorChanged, ...columnProps }, ref) => {
    const containerRef = useRef(null);
    const quillRef = useRef();
    useEffectWithCtx(ctx => {
        const container = containerRef.current;
        if (container) {
            const editorContainer = document.createElement('div');
            container.appendChild(editorContainer);
            const quill = createQuillInstance(editorContainer, {
                placeholder: ctx.placeholder,
            });
            quillRef.current = quill;
            if (ctx.initialValue) {
                setHTML(ctx.initialValue);
            }
            ctx.onEditorChanged?.(quill);
            return () => {
                quillRef.current = null;
                ctx.onEditorChanged?.(null);
                container.innerHTML = '';
            };
        }
    }, { initialValue, placeholder, readOnly, onEditorChanged }, []);
    useEffectWithCtx(() => {
        if (readOnly) {
            quillRef.current?.disable();
            renderMathInDocument();
        }
        else {
            quillRef.current?.enable();
        }
    }, {}, [readOnly]);
    const setDelta = useCallbackWithCtx((ctx, delta) => {
        if (quillRef.current) {
            quillRef.current.setContents(delta);
            renderMathInDocument();
        }
    }, { readOnly });
    const setHTML = useCallbackWithCtx((ctx, html) => {
        if (quillRef.current) {
            const delta = quillRef.current.clipboard.convert({
                html: ctx.readOnly
                    ? normalizeMathField(html)
                    : addMathFieldWrapper(html),
            });
            setDelta(delta);
        }
    }, { readOnly });
    useImperativeHandle(ref, () => ({
        setDelta,
        setHTML,
    }));
    const editorStyles = useStyles();
    return (React.createElement(Column, { ...columnProps },
        React.createElement(Helmet, null,
            React.createElement("style", null, editorStyles)),
        readOnly ? (React.createElement(Column, { ref: containerRef, flex: true })) : (React.createElement(ScrollView, null,
            React.createElement(Column, { ref: containerRef }),
            React.createElement(Column, { style: { height: 400 } })))));
});
export default QuillEditor;
