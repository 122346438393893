import { gql } from '@apollo/client';
import { addTypePolicies } from 'src/lib/graphql/cache';
import useGQLPaginatedQuery from 'src/lib/graphql/hooks/useGQLPaginatedQuery';
import paginatedListFieldPolicy from 'src/lib/graphql/utils/paginatedListFieldPolicy';
const QUESTION_SEARCH_QUERY = gql `
  query EP_QuestionSearch(
    $query: String!
    $pagination: PaginationOptions
    $ancestorID: ID
    $withAncestorsDetails: Boolean = false
  ) {
    ep_questionSearch(
      query: $query
      pagination: $pagination
      ancestorID: $ancestorID
    ) {
      totalCount
      list {
        id
        type
        data {
          question
          marks
          topics
          sequence
          label
          isSubQuestion
        }
        questionPaper {
          data {
            date
            label
          }
        }
        duplicates {
          questionPaper {
            data {
              date
              label
            }
          }
        }
        ancestorsDetails @include(if: $withAncestorsDetails) {
          community {
            id
            data {
              title
            }
          }
          examLevel {
            id
            data {
              title
            }
          }
          writtenSubject {
            id
            data {
              title
            }
          }
          writtenQP {
            id
            data {
              date
              label
            }
          }
          oralsSubject {
            id
            data {
              title
            }
          }
          oralsQP {
            id
            data {
              date
              label
            }
          }
        }
      }
    }
  }
`;
export function useEPQuestionSearchQuery(options) {
    return useGQLPaginatedQuery(QUESTION_SEARCH_QUERY, 'ep_questionSearch', options);
}
addTypePolicies({
    Query: {
        fields: {
            ep_questionSearch: paginatedListFieldPolicy([
                'query',
                'ancestorID',
            ]),
        },
    },
});
