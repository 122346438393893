import React, { useEffect, useState } from 'react';
import { useAnimatedStyle, withTiming } from 'react-native-reanimated';
import Button from 'src/components/10-atoms/button/Button';
import { AnimatedRow } from 'src/components/10-atoms/layout/Row';
import { PRESSABLE_SIZE } from 'src/components/templates/PressableArea';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useKeyboardHeight from 'src/hooks/keyboard/useKeyboardHeight';
import Typography from '../../10-atoms/typography/Typography';
import { SnackbarSlideDuration } from './constants';
const Snackbar = ({ visible, message, variant, action, }) => {
    const [{ height: keyboardHeight }] = useKeyboardHeight();
    const [{ styles, dimensions: { safeAreaInsets }, },] = useTheme(stylesGenerator, { keyboardHeight }, [keyboardHeight]);
    const snackbarPosAnimatedStyle = useAnimatedStyle(() => ({
        transform: [
            {
                translateY: withTiming(visible ? 0 : safeAreaInsets.bottom + 230, {
                    duration: SnackbarSlideDuration,
                }),
            },
        ],
    }), [safeAreaInsets.bottom, visible]);
    const [hide, setHide] = useState(true);
    useEffect(() => {
        let timer;
        if (!visible) {
            timer = setTimeout(setHide, SnackbarSlideDuration, true);
        }
        else {
            clearTimeout(timer);
            setHide(false);
        }
        return () => {
            clearTimeout(timer);
        };
    }, [visible]);
    return hide ? null : (React.createElement(AnimatedRow, { style: [
            styles.container,
            styles[variant],
            snackbarPosAnimatedStyle,
            {
                marginBottom: safeAreaInsets.bottom + 12,
            },
        ], yAxis: "center", spacing: 8 },
        React.createElement(Typography, { numberOfLines: 3, ellipsizeMode: "tail", flex: true, style: styles[`${variant}Text`] }, message),
        action && (React.createElement(Button, { variant: "text", noIconSpacing: true, onPress: action.onPress }, action.title))));
};
const stylesGenerator = createThemedStyle(({ theme }, { keyboardHeight }) => ({
    container: {
        position: 'absolute',
        bottom: 0 + keyboardHeight,
        left: theme.spacing.page,
        right: theme.spacing.page,
        borderRadius: theme.borderRadius.standard.md,
        paddingHorizontal: theme.spacing.standard.sm,
        paddingVertical: theme.spacing.standard.xxs,
        minHeight: PRESSABLE_SIZE,
    },
    POSITIVE: {
        backgroundColor: theme.palette.positive.main,
    },
    NEGATIVE: {
        backgroundColor: theme.palette.negative.main,
    },
    NEUTRAL: {
        backgroundColor: theme.palette.neutral.main,
    },
    POSITIVEText: {
        color: theme.palette.positive.contrast,
    },
    NEGATIVEText: {
        color: theme.palette.negative.contrast,
    },
    NEUTRALText: {
        color: theme.palette.neutral.contrast,
    },
}));
export default Snackbar;
