import React from 'react';
import Svg from 'react-native-svg';
import withPress from 'src/hoc/withPress';
import RowCenter from '../layout/RowCenter';
import Typography from '../typography/Typography';
import { TypographyVariant } from 'src/customizations/themes/10-parts/typography';
import { isWeb } from 'src/utils/platform';
import { StyleSheet } from 'react-native';
const units = isWeb ? 'px' : 'em';
const SVGIcon = withPress(({ size = 24, children, notificationCount, ...rest }) => (React.createElement(Svg, { height: `${size}${units}`, width: `${size}${units}`, viewBox: "0 0 24 24", ...rest },
    children,
    !!notificationCount && (React.createElement(RowCenter, { brd: size, bg: "red", ph: 2, style: styles.notificationCount },
        React.createElement(Typography, { variant: TypographyVariant.ICON_BADGE }, notificationCount))))));
const styles = StyleSheet.create({
    notificationCount: {
        position: 'absolute',
        top: 0,
        right: 0,
    },
});
export default SVGIcon;
