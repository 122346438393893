import React from 'react';
import { PRESSABLE_SIZE } from 'src/components/templates/PressableArea';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import withPress from 'src/hoc/withPress';
import Row from '../../layout/Row';
import useFormStyles from '../styles';
import { renderNamedSlot } from 'src/utils/component';
const InputLayoutComponent = React.forwardRef(({ LeftIcon, RightIcon, leftIconColor, rightIconColor, children, style, focused, disabled, ...rowProps }, ref) => {
    const [{ styles, theme }] = useTheme(stylesGenerator);
    const formStyles = useFormStyles({ focused, disabled });
    leftIconColor = leftIconColor || theme.palette.icon.primary;
    rightIconColor = rightIconColor || theme.palette.icon.primary;
    return (React.createElement(Row, { ref: ref, yAxis: "center", spacing: 8, style: [formStyles.inputContainer, styles.container, style], ...rowProps },
        renderNamedSlot(LeftIcon, {
            color: leftIconColor,
            size: theme.iconSize.sm,
        }),
        children,
        renderNamedSlot(RightIcon, {
            color: rightIconColor,
            size: theme.iconSize.sm,
        })));
});
const stylesGenerator = createThemedStyle(() => ({
    container: {
        minHeight: PRESSABLE_SIZE,
    },
}));
const InputLayout = withPress(InputLayoutComponent);
export default InputLayout;
