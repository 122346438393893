import React from 'react';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import FormulaIcon from 'src/components/10-atoms/icons/FormulaIcon';
import OrderedListIcon from 'src/components/10-atoms/icons/OrderedListIcon';
import Row from 'src/components/10-atoms/layout/Row';
import RowCenter from 'src/components/10-atoms/layout/RowCenter';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import ToolbarItem from './Item';
import { ToolbarAction, ToolbarActionState } from './types';
import EditLinkIcon from 'src/components/10-atoms/icons/EditLinkIcon';
import TableIcon from 'src/components/10-atoms/icons/TableIcon';
import { isIosWV } from 'src/utils/platform';
const ToolbarView = ({ mfFocused, actionsState, onActionPress, ...rowCenterProps }) => {
    const [{ styles, theme }] = useTheme(stylesGenerator);
    return (React.createElement(RowCenter, { pv: theme.spacing.standard.xs, style: styles.container, ...rowCenterProps },
        React.createElement(Row, { yAxis: "center", spacing: theme.spacing.standard.xs },
            React.createElement(ToolbarItem, { disabled: mfFocused, selected: actionsState.bold === ToolbarActionState.ACTIVE, icon: props => React.createElement(FeatherIcon, { name: "bold", ...props }), onPress: () => onActionPress(ToolbarAction.BOLD) }),
            React.createElement(ToolbarItem, { disabled: mfFocused, selected: actionsState.italic === ToolbarActionState.ACTIVE, icon: props => React.createElement(FeatherIcon, { name: "italic", ...props }), onPress: () => onActionPress(ToolbarAction.ITALIC) }),
            React.createElement(ToolbarItem, { disabled: mfFocused, selected: actionsState.unorderedList === ToolbarActionState.ACTIVE, icon: props => React.createElement(FeatherIcon, { name: "list", ...props }), onPress: () => onActionPress(ToolbarAction.UNORDERED_LIST) }),
            React.createElement(ToolbarItem, { disabled: mfFocused, selected: actionsState.orderedList === ToolbarActionState.ACTIVE, icon: OrderedListIcon, onPress: () => onActionPress(ToolbarAction.ORDERED_LIST) }),
            React.createElement(ToolbarItem, { disabled: mfFocused, icon: props => React.createElement(FeatherIcon, { name: "image", ...props }), onPress: () => onActionPress(ToolbarAction.IMAGE) }),
            isIosWV ? null : (React.createElement(ToolbarItem, { disabled: mfFocused || actionsState.table === ToolbarActionState.ACTIVE, icon: TableIcon, onPress: () => onActionPress(ToolbarAction.TABLE) })),
            actionsState.hyperLink === ToolbarActionState.INACTIVE ? (React.createElement(ToolbarItem, { disabled: mfFocused, icon: props => React.createElement(FeatherIcon, { name: "link-2", ...props }), onPress: () => onActionPress(ToolbarAction.HYPERLINK) })) : (React.createElement(ToolbarItem, { disabled: mfFocused, icon: EditLinkIcon, onPress: () => onActionPress(ToolbarAction.HYPERLINK) })),
            React.createElement(ToolbarItem, { disabled: mfFocused, icon: FormulaIcon, onPress: () => onActionPress(ToolbarAction.FORMULA) }))));
};
const stylesGenerator = createThemedStyle(() => ({
    container: {
        borderTopWidth: 1,
        maxWidth: '100%',
        overflowY: 'scroll',
    },
}));
export default ToolbarView;
